@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css);

@font-face {
  font-family: "dm_sansbold";
  src: local("dm_sansbold"),
    url(./fonts/dmsans-bold-webfont.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dm_sansmedium";
  src: local("dm_sansmedium"),
    url(./fonts/dmsans-medium-webfont.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dm_sansregular";
  src: local("dm_sansregular"),
    url(./fonts/dmsans-regular-webfont.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./fonts/Inter-Medium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* 
.ql-editor {
  height: 150px !important;
} */
.subText {
  margin-bottom: 0px;
  width: fit-content;
}
/* .image_div {
  height: 150px;
  width: 150px;
} */
.spanPrint {
  cursor: pointer;
}
.spantemp {
  cursor: pointer;
}
.ql-toolbar.ql-snow .spantemp {
  margin-right: 15px;
}
.ql-toolbar.ql-snow .spanPrint {
  margin-right: 15px;
}
#root .left_inputF .ql-editor {
  min-height: 15em;
}
.editbtn {
  position: absolute;
  right: 2%;
}
.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: 16px !important;
  margin-right: 16px !important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}
.logoutIcon {
  width: auto;
  height: 26px;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: auto 10px;
}
.logoutbtn {
  border: none;
  background-color: transparent;
}
.editTemText {
  font-size: 18px;
  font-weight: 500;
}
.commentMain {
  padding: 10px;
  background: #f2f2f7;
  width: 100%;
  margin: 8px 0px;
  position: relative;
}
.editCom {
  position: absolute;
  /* left: 0; */
  top: -3px;
  opacity: 0;
  transition: opacity 0.3s ease;
  right: 0;
}
.commentMainCgUser:hover .editCom {
  opacity: 1;
}
/* .ql-editor {
  -webkit-user-select: text !important;
} */
.commentMain:hover .editCom {
  opacity: 1;
}
.commentMainCgUser div p img {
  max-width: 100%;
  height: auto;
}
.commentMainCgUser {
  position: relative;
  width: 100%;
  padding: 10px;
  background: #49b3cb;
  color: white;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 8px 0px;
}
.divTemplDd .MuiInputBase-input {
  padding: 7px 11px 7px;
  margin-bottom: 12px;
  border: 1px solid rgb(178 178 178) !important;
}
.divTemplDd .MuiFormControl-root {
  width: 100%;
}

.removeEditBtn .editTemplate {
  display: none;
}
span.editTemplate {
  padding: 0px 0px 0px 20px;
  border-left: 1px solid black;
}
.mainEditTemplate > h6 {
  padding: 0px 30px 0px 0px;
}
em.mainEditTemplate {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/* em.mainEditTemplate > .h6 {
  width: 100%;
} */
.MuiMenuItem-root {
  font-size: 12px !important;
}
.MuiMenuItem-root h6 {
  font-size: 11px;
}
li.tempAddName {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}
.divTemplDd .MuiInput-underline:after {
  border-bottom: none !important;
}
.divTemplDd .MuiInput-underline:before {
  border-bottom: none !important;
}
.templStyle .card-header {
  /* background-color: #49b3cb !important; */
  position: relative;
}
.settingTempl {
  position: absolute;
  right: 12px;
  top: 3px;
  cursor: pointer;
}
.left_input.editBox {
  flex-basis: 97%;
  max-width: 100%;
}
.card-deck {
  /* flex-flow: row wrap; */
  /* display: flex; */
  margin-right: -15px;
  margin-left: -15px;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
}
.mainBlockComment {
  background: white;
  padding: 12px;
  border: 1px solid #b9b1b1;
  margin: 10px 0px;
  border-radius: 10px;
}
.noteshowStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.showIndivitualNotes {
  background-color: #fff;
  border: 1px solid #d4d4d4;
  width: 100%;
  border-radius: 10px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px;
  font-size: 16px;
  color: #000;
  resize: none;
  margin: 9px 0px;
  position: relative;
  cursor: pointer;
}
.showIndivitualNotes p {
  margin: 6px 0px;
}
body {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-family: sans-serif !important; */
}

p,
span,
div,
li,
label {
  font-size: 16px;
}

a,
input[type="submit"],
button {
  text-decoration: none;
  transition: all 0.3s;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}
ul {
  margin: 0;
  padding: 0;
  /* list-style: none; */
}
.autoCompleteInput {
  font: 200 20px/1.5 Helvetica, Verdana, sans-serif;
  border-bottom: 1px solid #ccc;
  border-radius: 5px;
  max-width: 200px;
}

ul.suggestAutocomplete {
  position: absolute;
  display: block;
  list-style-type: none;
  margin-top: 30px;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0px;
  top: 10px;
}

.suggestAutocomplete > li {
  font: 200 20px/1.5 Helvetica, Verdana, sans-serif;
  border-bottom: 1px solid #ccc;
}

.suggestAutocomplete > li:last-child {
  border: none;
}
.item {
  /* border: 1px solid lightblue; */
  /* margin: 5px; */
  padding: 5px;
}
/* .mainItem {
  border: 1px solid black;
  height: 200px;
  overflow-y: auto;
} */
.item.active,
.item:hover {
  background: #d3e1eb;
}

.item:hover {
  cursor: pointer;
}

.suggestAutocomplete > li {
  text-decoration: none;
  color: #000;
  display: block;
  width: 200px;
  transition: font-size 0.3s ease, background-color 0.3s ease;
}
.button_main_div_kk {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
/* <style> */
/* Style for accordion menu */
.accordion {
  position: relative;
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: all 1s ease-in-out;
}
/* Style for active accordion */
/* .active,
.accordion:hover {
  background-color: #ccc;
} */

/* Style for panel */
.ReadMore {
  border: 1px solid rgb(196, 191, 191);
  padding: 5px;
}
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0px;
}

.accordion-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 5px; */
}

.accordion-item span:first-child {
  flex: 1;
}

.accordion-item span:last-child {
  font-size: 12px;
  font-weight: normal;
  color: #999;
}
.WhiteBoxAD {
  gap: 30px;
  display: flex;
}
span.countLenNoti {
  position: absolute;
  right: -9px;
  /* border: 1px solid black; */
  top: 0px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #49b3cb;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}
.WhiteBoxNotiMain {
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.WhiteBoxAD button {
  border: 1px solid #49b3cb;
  color: white;
  background-color: #49b3cb;
}
p.CancelNotifi {
  position: absolute;
  right: 4px;
  top: 1px;
}
.WhiteBoxNotiSub {
  border: 2px solid #c9bfbf;
  background-color: white;

  padding: 10px 10px;
  position: relative;
  border-radius: 10px;
}
.WhiteBoxNotiSub p {
  font-size: 16px;
}
.darkFont {
  font-size: 18px;
  font-weight: 600;
}
.flex {
  display: flex;
  align-items: center;
}
.sticky_btn_save {
  /* position: sticky; */
  bottom: 0;
  /* width: 85.2% !important; */
  /* margin: 0 13px; */
  left: 0;
  right: 0;
}
.share-link {
  position: absolute;
  right: 20px;
  top: 50%;
  font-size: 17px;
  font-weight: 650;
  transform: translateY(-50%);
  display: none;
}
span.ViewUrl {
  margin-left: 12px;
  font-size: 12px;
  text-decoration-line: underline;
  color: #18adcf;
}
.accordion:hover .share-link {
  display: inline;
}
.sign {
  margin-left: 5px;
  font-size: 15px;
}

.count {
  font-size: 13px;
  color: #666;
  margin-left: 3px;
}
/* </style> */

.suggestAutocomplete > li:hover {
  font-size: 30px;
  background: #fff7aa;
}
.grpboxUl {
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.grpbox {
  width: 60%;
}
.button_main_div {
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	padding-top: 10px;
}
.read-more {
  position: absolute;
  bottom: 0;
  right: 0;
  background: white;
}
.headerNoteDate {
  font-size: 16px;
  color: #5c5454;
}
.read-more::before {
  content: "...";
  display: inline;
  margin: 0 5px;
}
.MuiAppBar-colorPrimary {
  background-color: #49b3cb !important;
}
.rta {
  overflow-x: hidden;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
}
.rta__autocomplete {
  width: 100%;
}
.ql-toolbar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 29;
  background: white;
}

.share_modal {
  width: 468 !important;
}
.MobView {
  display: none;
  padding: 10px !important;
}
.norView {
  display: block;
  padding: 20px;
}
.rta::-webkit-scrollbar {
  width: 5px;
}
.rta::-webkit-scrollbar-track {
  border-radius: 10px;
}
.rta::-webkit-scrollbar-thumb {
  background: #49b3cb;
  border-radius: 10px;
}
.rta__entity--selected {
  color: #000 !important;
  background-color: #d1d1d1 !important;
}
.main_wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
}
.left_form {
  flex-basis: 50%;
  max-width: 50%;
  position: relative;
  height: 100vh;
}
.right_logo {
  flex-basis: 50%;
  max-width: 50%;
  position: relative;
  height: 100vh;
  background: #49b3cb;
  border-radius: 0px 0px 0px 100px;
  text-align: center;
}
.logo {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}
.logo img {
  width: 20%;
}
.footer_link {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}
.footer_link ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_link ul li a {
  /* font-family: "dm_sansmedium"; */
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  padding: 0 20px;
}
.group {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
}
.inner_form {
  width: 410px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.inner_form h1 {
  margin: 0 0 8px;
  /* font-family: "dm_sansbold"; */
  font-size: 30px;
  line-height: 56px;
  color: #2b3674;
}
.inner_form p {
  /* font-family: "dm_sansmedium"; */
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 20px;
  color: #a3aed0;
}
.inner_form label {
  color: #2b3674;
  /* font-family: "dm_sansmedium"; */
  display: block;
  font-size: 12px;
  line-height: normal;
  margin: 0 0 8px;
}
.inner_form label span {
  color: #4318ff;
}
.inner_form .form_group {
  position: relative;
  margin-bottom: 14px;
}
.inner_form input {
  border: 1px solid #e0e5f2;
  padding: 10px 10px;
  width: 90%;
  border-radius: 10px;
  line-height: 18px;
  color: #a3aed0;
  font-size: 14px;
}
.inner_form input::placeholder {
  opacity: 1;
  color: #a3aed0;
}
.inner_form input[type="submit"] {
  background: #49b3cb;
  border-radius: 16px;
  color: #fff;
  border-color: #49b3cb;
  /* font-family: "dm_sansbold"; */
  cursor: pointer;
  width: 100%;
}
.inner_form input[type="submit"]:hover {
  background: transparent;
  color: #49b3cb;
}
.show_password {
  position: absolute;
  right: 20px;
  top: 33px;
  color: #a3aed0;
}
.sign_text p {
  font-size: 14px;
  margin: 0;
  color: #2b3674;
}
.sign_text p a {
  color: #49b3cb;
  /* font-family: "dm_sansbold"; */
}
.form_check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.remember {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-start;
}
.remember input {
  width: auto;
  margin: 0 10px 0 0;
  padding: 0;
}
.remember label {
  margin: 0;
  color: #2b3674;
  font-size: 14px;
}
.forgot_pass_link a {
  font-size: 14px;
  color: #49b3cb;
}
.copyright {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
}
.copyright p {
  margin: 0;
  color: #a3aed0;
  text-align: center;
  /* font-family: "dm_sansmedium"; */
  font-size: 14px;
}
.mainwrap {
  display: flex;
  /* height: 100vh; */
  background: #f4f7fe;
  justify-content: flex-end;
}
ol,
ul {
  padding-left: 0 !important;
}
.mainwrapNoti {
  display: flex;
  height: 100vh;
  background: #f4f7fe;
  justify-content: flex-end;
}
.textPlan {
  font-weight: 600;
  font-size: 17px;
}




.left_bar {
  flex-basis: 50px;
  max-width: 80px;
  padding: 20px;
  background: #fff;
  box-shadow: -8px 12px 18px 0 #b3bcd0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  height: 100%;
}
.container {
  border-bottom: none !important;
}
.metadata {
	text-align: right;
	padding-bottom: 9px;
}

.editnotetop {
	width: 100%;
}
.right_side {
	flex-basis: 95%;
	max-width: 95%;
	padding: 0px 10px 20px 3px;
	flex-basis: calc(100% - 90px);
	max-width: calc(100% - 90px);
	min-height: 100vh;
}
/* .top_logo {
  width: 40px;
  height: 40px;
  background: #49b3cb;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 30px;
  display: flex;
} */
.top_logo {
  background: #49b3cb;
  border-radius: 10px;
  display: flex;
  height: 50px;
  margin-bottom: 17px;
  padding: 5px;
  width: 50px;
}
.top_logo img {
  width: 60%;
  height: 60%;
  object-fit: contain;
  margin: auto;
  display: block;
}
.left_bar ul li {
  margin-bottom: 15px;
}
.left_bar ul li a {
  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6f6f6f;
  font-size: 22px;
}
.left_bar ul li a:hover,
.left_bar ul li.active a {
  background: #f0f0f0;
}
.swal2-container {
  z-index: 99999999 !important;
}
.border_box {
  border: 1px solid #b9b1b1;
  border-radius: 10px;
  padding: 11px 0 11px 11px;
  background: #f8f8f8;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.ddShareNote {
  height: 48px;
}
div.Dropdown-control {
  height: 100%;
  border-radius: 5px;
  border: 2px solid #dce0e8;
  width: 100px;
}
.select-search-input {
  border-radius: 5px !important;
}
.share_div {
  color: #000000;
  font-size: 20px;
  /* font-weight: 550; */
  font-weight: 500;
  text-align: center;
  margin-top: -21px;
  /* margin-bottom: 14px; */
}
.share_div2 {
  color: #000000;
  font-size: 20px;
  /* font-weight: 550; */
  font-weight: 500;
  text-align: center;
  margin-top: 21px;
  margin-bottom: 14px;
}
.left_align {
  gap: 5%;
  justify-content: right;
  display: flex;
}
span.font_dark {
  font-size: 15px;
  font-weight: 600;
}
span.font_Red {
  color: red;
  cursor: pointer;
}
.WhiteBox {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 15px;
}
.WhiteBoxNoPadding {
  background-color: #ffffff;
}
#canvas {
  display: block;
  margin: 20px auto 0 auto;
  border-bottom: 2px solid #9c27b0;
}
.input_AudioVoice {
  width: 90%;
  margin-bottom: 9px;
}

.col-6.switchG {
  display: flex;
  align-items: center;
}

.switchG .Dropdown-root {
  margin-right: 20px;
}

.switchG label .css-19a995p-Switch {
  position: absolute;
  top: 3px;
  bottom: 2px;
  right: 11px;
  width: 15px;
  left: 2px;
  height: 15px;
  background-color: #fff;
  border-radius: 9px;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
}
.switchG label .css-153wf2s-Switch {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 21px;
  width: 44px;
  border-radius: 11px;
  background-color: #cccccc;
}

.switchG label .css-jfsaru-Switch {
  background-color: #cccccc;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 21px;
  width: 44px;
  border-radius: 11px;
  background-color: #49b3cb;
}

.switchG label .css-1v96km2-Switch {
  position: absolute;
  top: 3px;
  bottom: 2px;
  right: 11px;
  width: 15px;

  height: 15px;
  background-color: #fff;
  border-radius: 9px;
  transition: all 100ms ease;
  left: 25px;
}
.wrapper {
  display: inline-block;
  position: relative;
}

.btnimg {
  pointer-events: none;
  width: 93px;
  /* height: 90px; */
  opacity: 1;
  border-radius: 50%;
  line-height: 90px;
  text-align: center;
}

.btnimgInput {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0px;
}
div.displayImages {
  /* padding: 15px; */
  position: relative;
  border: 1px solid #b9b1b1;
  padding: 5px;
}
div.extraGrid {
  display: grid;

  place-items: center;
}
div.py15 {
  padding: 15px;
  background-color: #f0f0f0;
}
.ReactGridGallery {
  justify-content: center;
  display: flex;

  /* border: 1px solid #525252; */
}

.proc-link {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  line-height: 19px;
  padding: 6px 10px;
  border-radius: 3px;
  margin: 15px 0;
}

div.gridImages {
  grid-gap: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, 156px) !important;
  padding: 10px;
}
.ql-editor .ql-size-small {
  font-size: 17px;
}

.ql-editor .ql-size-large {
  font-size: 30px;
}

.ql-editor .ql-size-huge {
  font-size: 40px;
}
span.ImageAdjust {
  margin: auto;
  display: flex;
  justify-content: center;
}
.ql-my-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.ql-hashtag::before {
  content: "#" !important;
  margin-right: 4px;
  display: block;
}

.ql-my-button:hover {
  background-color: #f4f4f4;
}

.ql-my-button img {
  width: 16px;
  height: 16px;
}

span.CancelImage {
  position: absolute;
  right: 5px;
  cursor: pointer;
}
span.topPosition {
  top: 5px;
}
.input_RecoredVoice {
  visibility: visible;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-radius: 5px;
  border: 2px solid #dce0e8;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  margin: 0px 0px 14px 0px;

  padding-bottom: 2px;
  padding-top: 2px;
  padding: 0px 10px;
  height: 48px;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
  width: 100%;
}
.input_SharedEmail {
  visibility: visible;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-radius: 5px;
  border: 2px solid #dce0e8;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  padding: 0px 10px;
  height: 48px;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
  width: 250px;
}
.share_two_dd {
  display: flex;
  gap: 10px;
  margin-top: 7px;
  margin-bottom: 7px;
  align-items: center;
}
.css-13cymwt-control {
  width: 310px;
  height: 48px;
}
.css-19bb58m {
  width: 310px;
  height: 48px;
}
.css-1wy0on6 {
  display: none;
}
div#unicorn2 {
  color: black;
}
.top_input_box2 .border_box {
  border: none;
  border-radius: 0px;
  background: #f8f8f8;
  margin-bottom: 15px;
  display: block;
  padding: 0px;
  align-items: center;
}
#grpboxP {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/* #read-more {
  visibility: hidden;
} */
.hide1 {
  position: absolute;
  right: 30px;
  font-size: 17px;
  font-weight: 650;
  display: none;
}

/* [class*="custom-hide"] {
  position: absolute;
  right: 30px;
  font-size: 17px;
  font-weight: 650;
  display: none;
} */

.hide2 {
  position: absolute;
  right: 30px;
  font-size: 17px;
  font-weight: 650;
  display: none;
}
.showIndivitualNotes {
  color: black !important;
}
.sharedPer {
  position: absolute;
  right: 90px;
}
.tooltip-back:hover .tooltiptext-inn {
  display: block;
}
body .ql-editor {
  /* padding-bottom: 20px !important; */
}
.tooltip-back a {
  text-decoration: none !important;
  color: black !important;
}
/* .ql-snow .ql-editor img {
  width: 200px;
  height: 200px;
  object-fit: contain;
} */
.TitleTextSpan {
  text-decoration: none !important;
  color: black !important;
}
a {
  text-decoration: none !important;
}
/* .tooltip p {
  color: black;
} */

.tooltiptext-inn {
  display: none;
  top: -50;
  /* height: 200px; */
  /* width: 200px; */
  border-radius: 10px;
  background-color: #424444;
  position: absolute;
  color: white;
  padding: 15px;
  z-index: 9999;
}

.hide5 {
  position: absolute;
  right: 30px;
  font-size: 17px;
  font-weight: 650;
  display: none;
}
.main_block:hover .hide5 {
  display: block;
  /* color: red; */
  cursor: pointer;
}
/* .myDIV:hover .hide1 {
  display: block;
  cursor: pointer;
}

.myDIV2:hover > .posFlex2 > .hide2 {
  display: block;
  cursor: pointer;
} */

.posFlex2:hover > .hide2 {
  display: block;
  cursor: pointer;
}

/* [class*="custom-main"]:hover > .posFlex2 [class*="custom-hide"] {
  display: block;
  cursor: pointer;
} */

.share_tag_cont {
  text-align: center;
  font-weight: 500;
}
.posFlex2 {
  position: relative;
  display: flex !important;
}
.posFlex {
  position: relative;
  /* display: flex; */
}
.posFlex1 {
  display: flex;
}
div.css-1cfo1cf {
  width: 310px;
  height: 48px;
}
div.css-qbdosj-Input {
  width: 310px;
  height: 48px;
}
div.css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.css-166bipr-Input {
  width: 310px;
  height: 48px;
}
.top_input_box {
  margin-bottom: 15px;
}
.top_input_box_edit {
  margin-bottom: 15px;
}
.top_input_box_comment {
  margin-bottom: 15px;
}
.top_input_box_green {
  margin-top: 15px;
  margin-bottom: 10px;
}
.top_input_box_diff {
  background-color: #f4f7fe;
  border: 1px solid #49b3cb;
  color: #49b3cb;
  font-weight: 600;
  padding: 4px 23px;
  margin-bottom: 15px;
}
.top_input_box_diff_Save {
  background-color: #49b3cb;
  border: 1px solid #49b3cb;
  color: #ffffff;
  font-weight: 600;
  padding: 4px 23px;
  margin-bottom: 15px;
}
.commentNoteMain {
  position: relative;
  display: inline-block;
  display: flex;
  gap: 10px;
}
span.TimeComment {
  font-size: 10px;
  color: grey;
}
.TimeCommentUser {
  font-size: 11px;
  color: white;
}
.commentNoteMain .commentMain::after {
  /* content: "";
  position: absolute;
  top: 20%;
  right: 92%;
  margin-top: -5px;
  border-width: 13px;
  border-style: solid; */
  /* border-color: transparent #f2f2f7 transparent transparent; */
}
.commentMainCgUser .commentMain::after {
  /* content: "";
  position: absolute;
  top: 20%;
  right: 92%;
  margin-top: -5px;
  border-width: 13px;
  border-style: solid;
  border-color: transparent #49b3cb transparent transparent; */
}
.left_inputF .ql-toolbar.ql-snow {
  border: none !important;
}
.left_inputF .ql-container.ql-snow {
  border: none !important;
}
.left_inputF1 .ql-toolbar.ql-snow {
  border: none !important;
}
.left_inputF1 .ql-container.ql-snow {
  border: none !important;
}
.left_inputF {
  border: 1px solid #ccc;
  /* flex-basis: calc(100% - 200px); */
  /* max-width: calc(100% - 200px); */
  width: 78%;
  flex-basis: 100%;
  max-width: 100%;
  position: relative;
  background-color: #ffffff;
}
.left_inputF1 {
  border: 1px solid #ccc;
  /* flex-basis: calc(100% - 200px); */
  /* max-width: calc(100% - 200px); */
  width: 78%;
  flex-basis: 100%;
  max-width: 100%;
  position: relative;
  background-color: #ffffff;
}
.left_input1 {
  /* flex-basis: calc(100% - 200px); */
  /* max-width: calc(100% - 200px); */
  flex-basis: 100%;
  max-width: 100%;
  position: relative;
}
.left_input1 textarea {
  border: none;
  /* border: 1px solid #d4d4d4; */
  width: 100%;
  height: 150px;
  padding: 20px;
  font-size: 16px;
  color: #000;
  resize: none;
}
.left_input {
  /* flex-basis: calc(100% - 200px); */
  /* max-width: calc(100% - 200px); */
  flex-basis: 100%;
  max-width: 100%;
  position: relative;
}
.left_input textarea {
  border: none;
  /* border: 1px solid #d4d4d4; */
  width: 100%;
  height: 150px;
  padding: 20px;
  font-size: 16px;
  color: #000;
  resize: none;
}
.right_text {
	flex-basis: 185px;
	max-width: 185px;
	padding: 0 20px;
	top: 0px;
	bottom: 130px;
	position: sticky;
	text-align: center;
	cursor: pointer;
	width: 22%;
}
/* .right_text ul{
  margin-left: 50px;
} */
.right_text ul li span {
  width: 15px;
  display: inline-block;
  margin-right: 5px;

  text-align: end;
}
.right_text i {
  font-size: 35px;
  color: #525252;
  /* margin-bottom: 15px; */
}
.right_text h2 {
  margin: 0;
  color: #525252;
  margin-bottom: 12px;
  font-size: 18px;
}
.right_text ul li {
  text-align: left;
  color: #525252;
  font-size: 13px;
  line-height: 30px;
}
#timer {
  font-size: 40px;
}
.top_input_box button {
  background: #49b3cb;
  border: 1px solid #49b3cb;
  border-radius: 8px;
  width: 100%;
  padding: 6px;
  font-size: 26px;
  color: #fff;
  /* font-family: "dm_sansbold"; */
  cursor: pointer;
}
.top_input_box_edit button {
  background: #49b3cb;
  border: 1px solid #49b3cb;
  border-radius: 8px;
  width: 100%;
  padding: 6px;
  font-size: 26px;
  color: #fff;
  position: sticky;
  /* font-family: "dm_sansbold"; */
  cursor: pointer;
}
.top_input_box_half button {
  background: #49b3cb;
  border: 1px solid #49b3cb;
  border-radius: 8px;
  width: 100%;
  padding: 6px;
  font-size: 15px;
  color: #fff;
  /* font-family: "dm_sansbold"; */
  cursor: pointer;
}
.top_input_box2 .buttonEdit {
  background: #49b3cb;
  border: 1px solid #49b3cb;
  border-radius: 8px;
  width: 100%;
  padding: 6px;
  font-size: 26px;
  color: #fff;
  /* font-family: "dm_sansbold"; */
  cursor: pointer;
}
.top_input_box2 .buttonEdit:hover {
  background: white;
  color: #49b3cb;
}
.top_input_box_comment button {
  background: #fff;
  border: 1px solid #49b3cb;
  border-radius: 8px;
  width: 100%;
  padding: 6px;
  font-size: 26px;
  color: #49b3cb;
  /* font-family: "dm_sansbold"; */
  cursor: pointer;
}
.top_input_box_green button {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #7cbb4b;
  border: 1px solid #b9b1b1;
  border-radius: 8px;
  width: 100%;
  padding: 2px;
  font-size: 26px;
  color: #fff;
  /* font-family: "dm_sansbold"; */
  cursor: pointer;
}
.top_input_box_Red {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), red !important;
  border: 1px solid #b9b1b1;
  border-radius: 8px;
  width: 100%;
  padding: 2px;
  font-size: 26px;
  color: #fff;
  /* font-family: "dm_sansbold"; */
  cursor: pointer;
}
.top_input_box button:hover {
  background: transparent;
  color: #49b3cb;
}
.top_input_box_edit button:hover {
  background: transparent;
  color: #49b3cb;
}
.top_input_box_comment button:hover {
  background: #49b3cb;
  border: 1px solid #49b3cb;
  color: #fff;
}
.search_box input {
  background: transparent;
  border: 0;
  font-size: 16px;
  color: #c0bfbd;
  width: 100%;
  line-height: 22px;
  /* font-family: "dm_sansmedium"; */
}
.search_box input::placeholder {
  opacity: 1;
  color: #c0bfbd;
}
.search_box i {
  color: #c0bfbd;
  font-size: 16px;
  margin-right: 10px;
}
.search_box {
	background: #424444;
	border-radius: 8px;
	padding: 13px 16px;
	margin-bottom: 11px;
	margin-top: 10px;
}

/* message chat */

.chat-container {
  position: relative;
  padding: 1rem;
  background: #ffffff;
}

.chat-container li.chat-left,
.chat-container li.chat-right {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 40px;
}
.chat-container li .chat-avatar {
  margin-right: 20px;
}
.chat-container li img {
  width: 48px;
  height: 48px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.chat-container li .chat-name {
  font-size: 0.75rem;
  color: #999999;
  text-align: center;
}

.chat-container li .chat-text {
  padding: 0.4rem 1rem;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  font-weight: 300;
  line-height: 150%;
  position: relative;
}
.chat-container li .chat-text:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0px;
  left: -18px;
  border: 10px solid;
  border-color: transparent #f2f2f7 transparent transparent;
}
.chat-container li .chat-text.theme2:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0px;
  left: -18px;
  border: 10px solid;
  border-color: transparent #49b3cb transparent transparent;
}
.chat-container li .chat-text.theme2 {
  color: #fff;
}
.chat-container li .chat-hour {
  padding: 0;
  margin-bottom: 10px;
  font-size: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 15px;
}
.chat-container li .chat-hour > span {
  font-size: 16px;
  color: #9ec94a;
}

.chat-container li.chat-right > .chat-text {
  text-align: right;
}
.chat-container li.chat-right > .chat-text:before {
  right: -20px;
  border-color: transparent transparent transparent #ffffff;
  left: inherit;
}
.chat-container li.chat-right > .chat-hour {
  margin: 0 15px 0 0;
}
.chat-container li .chat-hour > span {
  font-size: 16px;
  color: #9ec94a;
}
.chat-container li.chat-right {
  justify-content: flex-end;
}

.chat-container li.chat-right > .chat-avatar {
  margin-left: 20px;
  margin-right: 0;
}
.ql-container {
  min-height: 170px !important;
}
.theme {
  background-color: #f2f2f7;
}
.theme2 {
  background-color: #49b3cb;
}
button.logoutbtn {
  padding: 0 3px !important;
}
/* .ql-font-roboto {
  font-family: rob;
} */
.ql-font-serif {
  font-family: serif;
}
.ql-font-sans-serif {
  font-family: sans-serif;
}
.ql-font-monospace {
  font-family: monospace;
}
div.side_bar.ul {
  list-style: none !important;
}
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Montserrat {
  font-family: "Montserrat";
}
.ql-font-Lato {
  font-family: "Lato";
}
.ql-font-Rubik {
  font-family: "Rubik";
}
.ql-font-inconsolata {
  font-family: "Inconsolata";
}

.main-app {
  /* margin: 2% 10%;  */
  /* border: 1px ridge #464141; */
  /* padding: 2%; */
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  /* font-size: 20px;
  color: #464141; */
}

.container {
  border-bottom: #fff 2px ridge;
}

.pagination {
  /* margin-top: 45px; */
  /* margin-left: -40px; */
  /* display: flex; */
  /* list-style: none; */
  /* outline: none; */
  justify-content: center;
}

.pagination > .active > a {
  background-color: #47ccde;
  color: #fff;
}

.pagination > li > a {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.bgchgd {
  background-color: #f4f7fe !important;
}
.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
  background-color: azure;
}
.PrivateTabIndicator-colorSecondary-7 {
  background-color: #49b3cb !important;
}
.mainDivScrollShare {
  overflow-y: scroll;
  padding: 0px 12px;
}
.search_box_div {
  display: flex;
}
span.MuiTab-wrapper {
  font-size: 14px;
  font-weight: 550;
}
/* for dropdown css */
.ql-editor {
  line-height: unset;
  position: relative;
  padding: 12px 15px 0 !important;
  overflow-y: hidden !important;
}
.select-box--container {
  height: 30px;
  border: none;
  background-color: #d9d8d8;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0em;
}

.ql-container.ql-snow {
  position: relative;
  height: 100%;
}
/* .ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}
.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
} */
/* .ql-indent-1 {
  padding-left: 2.5em;
}
.grpboxUl ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}
.grpboxUl ol li.ql-indent-1 {
  counter-increment: list-1;
}
.grpboxUl li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
  text-align: right;
  margin-right: 0.3em;
  margin-left: -1.5em;
} */
/* .grpboxUl li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
} */
/* ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
} */
.grpbox ol {
  padding-left: 0em !important;
}
.MuiAutocomplete-listbox {
  border: 1px solid #f0f0f0;
  /* margin: 0; */
  /* padding: 15px 0; */
  /* overflow: auto; */
  /* overflow-y: scroll; */

  /* scroll-behavior: smooth; */
  list-style: none;
  /* max-height: 40vh; */
}
.MuiAutocomplete-popper {
  width: 74% !important;
  left: 148px !important;
}
.MuiAutocomplete-inputRoot {
  opacity: 0;
}

/* .MuiFormControl-root input {
  display: none;
} */
.MuiAutocomplete-endAdornment {
  display: none;
}

.select-box--box {
  width: 200px;
  position: absolute;
  left: 15px;
}

.select-box--selected-item {
  display: inline-block;
  background-color: #e0e0e0;
  height: 100%;
  width: 100%;
  padding: 4px 12px;
  vertical-align: middle;
}

.select-box--items div {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 6px;
  padding-left: 20px;
}
/* .ql-container.ql-snow {
  min-height: 190px !important;
} */
.ql-editor.ql-blank {
  min-height: 200px;
}
/* .activeTag,
.MuiAutocomplete-listbox {
  position: absolute;
  background: #fff;
} */
/*  */
.item {
  border-bottom: 1px solid #e4e3e3;
}
.Active_Float {
  position: absolute;
  background: #fff;
  z-index: 999;
  width: 49%;
  word-break: break-all;
  /* height: 230px; */
  overflow-y: scroll;
}
.Active_Float1 {
  position: absolute;
  background: #fff;
  z-index: 999;
  width: 49%;
  word-break: break-all;
  /* height: 230px; */
  overflow-y: scroll;
}
/* .ril-image-current {
  width: 2000px;

  transform: translate3d(-500px, 0px, 0px) scale3d(0.201667, 0.201667, 2) !important;
} */

@media (max-width: 992px) {
  .MuiDrawer-paper {
    display: block !important;
    }

  .inner_form {
    width: 100% !important;
    padding: 0 10px !important;
    top: inherit !important;
    left: inherit !important;
    position: inherit !important;
    transform: inherit !important;
  }
  .main_wrap {
    flex-direction: column-reverse;
    height: auto !important;
    width: 100% !important;
  }
  .right_logo {
    flex-basis: 100% !important;
    max-width: 100% !important;
    height: 400px !important;
    width: 100% !important;
    margin-bottom: 20px;
  }
  .left_form {
    flex-basis: 100% !important;
    max-width: 100% !important;
    height: auto !important;
  }
  .copyright {
    position: inherit !important;
    margin-top: 20px;
    bottom: inherit !important;
  }
  .logo,
  .footer_link {
    margin: 20px 0;
    top: inherit !important;
    bottom: inherit !important;
    left: inherit !important;
    position: inherit !important;
    transform: inherit !important;
  }
}

@media (max-width: 1024px) {
  .MuiAutocomplete-popper {
    width: 66% !important;
  }
}
@media (max-width: 1000px) {
  .ql-editor li:not(.ql-direction-rtl)::before {
    /* margin-left: -2.5em !important; */

    margin-right: 0.3em !important;

    text-align: right !important;
  }

  .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3.5em !important;
  }

  .ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 5.5em !important;
  }

  .ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 7.5em !important;
  }

  .ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 9.5em !important;
  }

  .ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 11.5em !important;
  }

  .ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 13.5em !important;
  }

  .ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 14.5em !important;
  }

  .ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 16.5em !important;
  }

  .ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 18.5em !important;
  }

  .ql-editor li.ql-indent-10:not(.ql-direction-rtl) {
    padding-left: 20.5em !important;
  }
}
@media (max-width: 912px) {
  .MuiAutocomplete-popper {
    width: 63% !important;
  }
}
@media (max-width: 820px) {
  .MuiAutocomplete-popper {
    width: 59% !important;
  }
}
@media (max-width: 768px) {
  .MuiAutocomplete-popper {
    width: 56% !important;
  }
}
@media (max-width: 600px) {
  .share_modal {
    width: 100% !important;
  }
  /* .sharedPer {
    position: unset;
    right: 90px;
  } */
  .sharedPer {
    display: flex;
    align-items: center;
  }

  .sharedPer span {
    display: none;
  }
  .css-13cymwt-control,
  .css-19bb58m {
    height: 48px;
    width: 248px;
  }
}
@media (max-width: 576px) {
  .group,
  .inner_form .form_group {
    width: 100% !important;
  }
  .MuiAutocomplete-popper {
    width: 50% !important;
  }
}
@media (max-width: 563px) {
  .button_main_div_kk {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
}
@media (max-width: 450px) {
  .metadata {
    width: 100%;
    padding-bottom: 7px;
    text-align: center;
  }

  .headerNoteDate {
    /* font-size: 16px; */
    display: flex;
    align-items: center;
    justify-items: center;
    flex-wrap: wrap;
  }
  .top_input_box {
    /* margin-top: 70px; */
  }
  .button_main_div_kk {
    /* margin-top: 40px; */
  }
  .right_text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 13px !important;
  }
  .editMar {
    margin-bottom: -31px !important;
  }
  .mainwrap {
    flex-direction: column;
    padding: 8px;
  }
  .left_inputF {
    width: 96% !important;
  }
  .left_inputF1 {
    width: 96% !important;
  }
  .border_box {
    flex-direction: column;
    padding: 7px 0 7px 0px !important;
    /* padding: 20px; */
  }
  .space_mobRes {
    margin-top: 46px !important;
  }
  .left_input {
    margin-bottom: 20px;
  }
  .right_text ul li {
    text-align: left;
    color: #525252;
    font-size: 11px;
    line-height: 17px;
  }
  .right_text h2 {
    margin: 0;
    color: #525252;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .right_text i {
    font-size: 30px;
    color: #525252;
    /* margin-bottom: 15px; */
  }
  .MobView {
    display: block;
    width: 60px;
  }

  .editnotetop {
    width: 100%;
    }


  .norView {
    display: none;
  }
  .right_side {
    flex-basis: 100%;
    max-width: 100%;
    /* flex-basis: calc(100% - 59px) !important;
    max-width: calc(100% - 62px) !important; */
    padding: 0px;
  }

  /* .ql-container,
  .ql-editor,
  .ql-editor.ql-blank,
  .ql-editor.ql-blank::before,
  .ql-editor.ql-blank::after {
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
    -webkit-user-select: text !important;
  } */
  span.ql-formats .ql-blockquote {
    display: none !important;
  }
  span.ql-formats .ql-code-block {
    display: none !important;
  }
  .ql-header .ql-picker .ql-expanded {
    display: none !important;
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0px !important;
  }
  .ql-header {
    display: none !important;
  }
  /* .left_bar {

    position: absolute;
    height: unset;
    box-shadow: unset;
  } */
  .border_box > div {
    width: 100%;

    /* padding: 0 !important; */
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (max-width: 325px) {
  .noteshowStyle {
    display: flex;
    flex-direction: column;
  }
  .grpbox {
    width: 100%;
  }
}
@media (min-width: 576px) {
  .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

/* position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 29;
    background: white; */
